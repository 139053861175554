<template>
    <div class="pb-1 px-1 mt-2">
        <LoaderComponent v-if="!isLoadedProducts"/>
        <router-view v-else/>
    </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import {mapGetters} from "vuex";

export default {
    name: 'index',
    components: {LoaderComponent},
    computed: {
        ...mapGetters({
            isLoadedProducts: 'isLoadedProducts'
        }),
    }
}
</script>

<style scoped>

</style>
